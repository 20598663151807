import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "./Alert";
import ReactPlayer from "react-player";
import { green } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  player: {
    marginTop: 24,
  },
  saveButton: {
    marginTop: 24,
    width: "100%",
  },
}));

const projectId = "newagent-e3782";

function LinkManagement({ token }) {
  const classes = useStyles();

  const [screen, setScreen] = useState(0);
  const [screensList, setScreensList] = useState([]);
  const [screensMap, setScreensMap] = useState(new Map());

  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [greenScreen, setGreenScreen] = useState(false);

  const [savedSnackbarOpen, setSavedSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  const handleScreenChange = (event) => {
    setScreen(event.target.value);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleGreenScreeChange = (event) => {
    setGreenScreen(event.target.checked);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSavedSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSavedSnackbarOpen(false);
  };

  const handleErrordSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSnackbarOpen(false);
  };

  const save = useCallback(async () => {
    try {
      await fetch(
        `https://${projectId}.firebaseio.com/screenes/${screen}.json?auth=${token}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ url, description, greenScreen }),
        }
      );
      screensMap.set(screen, { url, description, greenScreen });
      setSavedSnackbarOpen(true);
    } catch (error) {
      setErrorSnackbarOpen(true);
    }
  }, [screen, url, token, description, screensMap, greenScreen]);

  const fetchScreens = useCallback(async () => {
    try {
      const response = await fetch(
        `https://${projectId}.firebaseio.com/screenes.json?auth=${token}`
      );

      const data = await response.json();
      if (data) {
        const list = Object.keys(data);
        setScreensList(list);
        setScreensMap(new Map(list.map((s) => [s, data[s]])));
      }
    } catch (error) {
      setErrorSnackbarOpen(true);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchScreens();
    }
  }, [token, fetchScreens]);

  useEffect(() => {
    let data;
    if (typeof screen != "number") {
      data = screensMap.get(screen);
    } else {
      data = screensMap.get(`${screen}`);
    }

    if (data) {
      setUrl(data.url);
      setDescription(data.description);
      setGreenScreen(!!data.greenScreen);
    } else {
      setUrl("");
      setDescription("");
      setGreenScreen(false);
    }

    // range(1, 101).forEach((s) => {
    //   if (!screensMap.has(s)) {
    //     fetch(
    //       `https://${projectId}.firebaseio.com/screenes/${s}.json?auth=${token}`,
    //       {
    //         method: "PUT",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //           url: "",
    //           description: "",
    //           greenScreen: false,
    //         }),
    //       }
    //     );
    //   }
    // });
  }, [screensMap, screen]);

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel id="screen-label">Screen</InputLabel>
        <Select id="screen" value={screen} onChange={handleScreenChange}>
          {screensList.length > 0 &&
            screensList.map((screen) => (
              <MenuItem key={screen} value={screen}>
                Screen&nbsp;{screen}
                {screensMap.has(screen) &&
                  screensMap.get(screen).description.length > 0 && (
                    <>&nbsp;-&nbsp;{screensMap.get(screen).description}</>
                  )}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        className={classes.formControl}
        id="url"
        label="Enter HLS URL (m3u8)"
        value={url}
        onChange={handleUrlChange}
      />
      <TextField
        className={classes.formControl}
        id="description"
        label="Description"
        value={description}
        onChange={handleDescriptionChange}
      />
      <FormControlLabel
        control={
          <GreenCheckbox
            checked={greenScreen}
            onChange={handleGreenScreeChange}
            name="greenScreen"
          />
        }
        label="Green screen"
      />
      {url.length > 0 && (
        <ReactPlayer
          className={classes.player}
          url={url}
          controls
          width="100%"
          height="auto"
          config={{
            file: {
              hlsOptions: {
                xhrSetup: function (xhr, url) {
                  xhr.withCredentials = false;
                },
              },
            },
          }}
        />
      )}
      <Button
        className={classes.saveButton}
        variant="contained"
        color="secondary"
        onClick={save}
      >
        Save
      </Button>
      <Snackbar
        open={savedSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSavedSnackbarClose}
      >
        <Alert onClose={handleSavedSnackbarClose} severity="success">
          Link saved.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleErrordSnackbarClose}
      >
        <Alert onClose={handleSavedSnackbarClose} severity="error">
          An error occured.
        </Alert>
      </Snackbar>
    </>
  );
}

export default LinkManagement;

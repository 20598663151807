import React, { useState, useEffect } from "react";
import "./App.css";
import LinkManagement from "./components/LinkManagement";
import Login from "./components/Login";
import firebaseConfig from "./firebaseConfig";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "./components/Alert";

const useStyles = makeStyles(() => ({
  toolbar: {
    justifyContent: "flex-end",
  },
}));

function App() {
  const classes = useStyles();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [token, setToken] = useState(null);
  const [noPermissionsSnackbar, setNoPermissionsSnackbar] = useState(false);

  useEffect(() => {
    firebaseConfig.auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
      if (firebaseUser) {
        firebaseConfig.allowedUsersCollection
          .where("email", "==", firebaseUser.email)
          .get()
          .then((allowedUser) => {
            if (allowedUser.size > 0) {
              setVerified(true);
            } else {
              showNoPermissionsSnackbar();
              firebaseConfig.auth.signOut();
            }
          });
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    firebaseConfig.auth.onIdTokenChanged(async (firebaseUser) => {
      if (firebaseUser) {
        const newToken = await firebaseUser.getIdToken();
        setToken(newToken);
      } else {
        setToken(null);
      }
    });
  }, []);

  const handleLogout = () => {
    firebaseConfig.auth.signOut();
  };

  const showNoPermissionsSnackbar = () => {
    setNoPermissionsSnackbar(true);
  };

  const closeNoPermissionsSnackbar = () => {
    setNoPermissionsSnackbar(false);
  };

  return (
    <>
      <AppBar position="static" color="default">
        <Toolbar variant="dense" className={classes.toolbar}>
          {user && <Button onClick={handleLogout}>Logout</Button>}
        </Toolbar>
      </AppBar>
      <div className="App">
        <>
          {!loading && (
            <header className="App-header">
              {user && verified && <LinkManagement token={token} />}
              {!user && (
                <Login
                  onTokenChange={setToken}
                  onUserChange={setUser}
                  onVerified={setVerified}
                  showNoPermissionsSnackbar={showNoPermissionsSnackbar}
                />
              )}
            </header>
          )}
          <Snackbar
            open={noPermissionsSnackbar}
            autoHideDuration={6000}
            onClose={closeNoPermissionsSnackbar}
          >
            <Alert onClose={closeNoPermissionsSnackbar} severity="error">
            To manage live streams please ask the project owner for the necessary permissions.
            </Alert>
          </Snackbar>
        </>
      </div>
    </>
  );
}

export default App;

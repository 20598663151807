import React, { useCallback } from "react";
import Button from "@material-ui/core/Button";
import firebaseConfig from "../firebaseConfig";
import GoogleIcon from "./GoogleIcon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#fff",
  },
}));

function Login({
  onTokenChange,
  onUserChange,
  onVerified,
  showNoPermissionsSnackbar,
}) {
  const classes = useStyles();

  const handleClick = useCallback(async () => {
    const { user } = await firebaseConfig.auth.signInWithPopup(
      firebaseConfig.googleAuthProvider
    );

    if (user) {
      const allowedUser = await firebaseConfig.allowedUsersCollection
        .where("email", "==", user.email)
        .get();

      if (allowedUser.size > 0) {
        const token = await user.getIdToken();

        onTokenChange(token);
        onUserChange(user);
        onVerified(true);
      } else {
        onVerified(false);
        showNoPermissionsSnackbar();
        firebaseConfig.auth.signOut();
      }
    }
  }, [onUserChange, onTokenChange, onVerified, showNoPermissionsSnackbar]);

  return (
    <>
      <Button
        className={classes.button}
        startIcon={<GoogleIcon />}
        color="default"
        onClick={handleClick}
        size="large"
        variant="contained"
      >
        Sign in with Google
      </Button>
    </>
  );
}

export default Login;
